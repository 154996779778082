import React, { useContext, useState } from "react";
import {
  Row,
  Col,
  Image,
  Typography,
  ConfigProvider,
  Button,
  Dropdown,
} from "antd";
import { screenWidths } from "../const/const_functions";
import useWindowDimensions from "../hooks/useWindowDimensions";
import { useBrandContext } from "../contexts/Brand";
import { useChain } from "../contexts/Chain";
import {
  IoMdCart,
  IoMdPerson,
  IoMdLogOut,
  IoIosArrowDown,
} from "react-icons/io";
import { useLocation, useNavigate } from "react-router-dom";
import "../styles/WhiteLabelHeader.css";
import DonateDrawer from "./DonateDrawer";
import AnnouncementDrawer from "./AnnouncementDrawer";
import SelectLocationModal from "../components/SelectLocationModal";
import { useAuth } from "../contexts/Auth";
const { Text } = Typography;

const WhiteLabelHeader = ({ executeScroll }) => {
  const navigate = useNavigate();
  const { theme } = useContext(ConfigProvider.ConfigContext);
  const { brandContextData, loadingBrandContextData } = useBrandContext();
  const { featuredItems, locationSelected } = useChain();
  const { logoutUser, authData, userDetails } = useAuth();

  const [showDrawer, setShowDrawer] = useState(false);
  const [showAnnouncementDrawer, setShowAnnouncementDrawer] = useState(false);
  const [showSelectLocationModal, setShowSelectLocationModal] = useState(false);
  let mobile = false;

  const { width } = useWindowDimensions();

  let baseReached = false;
  if (width <= 1200) {
    baseReached = true;
  }

  if (screenWidths.md > width) {
    mobile = true;
  }

  const location = useLocation();

  // If we are in the context of getit payments, do not show the header on these pages
  if (
    (location.pathname.includes("/order") ||
      location.pathname.includes("/checkout") ||
      location.pathname.includes("/pay") ||
      location.pathname.includes("/myorders")) &&
    brandContextData._id === "a5121e36-getit-technologies"
  ) {
    return null;
  }

  const { ghostEventStores, stadiumListing, enableAccounts } = brandContextData;
  let stadiums = [];
  if (!brandContextData.isChain && stadiumListing?.length) {
    stadiums = stadiumListing.filter(
      (fStadium) =>
        fStadium._id !== "k8dfXum85newkBBE4" &&
        fStadium._id !== "GYT54hH3dxCQ7PNSy"
    );
    if (ghostEventStores) {
      stadiums = stadiums.filter(
        (fStadium) => !fStadium.stadTypes.includes("STT_VENUE")
      );
    }
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        backgroundColor: theme.token.colorPrimary,
        position: brandContextData?.headerFixed ? "sticky" : "initial",
        zIndex: 999,
        top: 0,
        paddingLeft: baseReached ? 12 : 0,
        paddingRight: baseReached ? 12 : 0,
      }}
    >
      <Row
        style={{
          paddingBottom: 10,
          paddingTop: 10,
          // position: brandContextData?.headerFixed ? "sticky" : "initial",
          // zIndex: 999,
          // width: 1200,
          // top: 0,
          // paddingLeft: baseReached ? 12 : 0,
          // paddingRight: baseReached ? 12 : 0,
        }}
      >
        {mobile ? (
          <Col
            span={24}
            style={{
              justifyContent: "space-between",
              display: "flex",
              padding: "3px 10px 0 10px",
            }}
          >
            <Col>
              {enableAccounts && !authData ? (
                <Text
                  style={{
                    marginBottom: 10,
                    color: theme.token.colorSecondary,
                    fontWeight: "bold",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    navigate("/login");
                  }}
                >
                  Login
                </Text>
              ) : null}
              {authData && enableAccounts ? (
                <Dropdown
                  menu={{
                    items: [
                      {
                        key: "1",
                        label: (
                          <div onClick={() => navigate("/user-account")}>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <IoMdPerson
                                style={{
                                  fontSize: 16,
                                  paddingRight: 5,
                                  color: "white",
                                }}
                              />
                              <Text
                                style={{
                                  fontWeight: 600,
                                  fontSize: 14,
                                  color: "white",
                                }}
                              >
                                Account
                              </Text>
                            </div>
                          </div>
                        ),
                      },
                      {
                        key: "2",
                        label: (
                          <div onClick={() => logoutUser()}>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <IoMdLogOut
                                style={{
                                  fontSize: 16,
                                  paddingRight: 5,
                                  color: "white",
                                }}
                              />
                              <Text
                                style={{
                                  fontWeight: 600,
                                  fontSize: 14,
                                  color: "white",
                                }}
                              >
                                Logout
                              </Text>
                            </div>
                          </div>
                        ),
                      },
                    ],
                  }}
                  dropdownRender={(menu) => (
                    <div>
                      {React.cloneElement(menu, {
                        style: {
                          boxShadow: "none",
                          backgroundColor: theme.token.colorSecondary,
                        },
                      })}
                    </div>
                  )}
                >
                  <Text
                    style={{
                      marginBottom: 10,
                      color: theme.token.colorSecondary,
                      fontWeight: "bold",
                      cursor: "pointer",
                      marginRight: 3,
                    }}
                  >
                    {userDetails?.profile?.firstName ? (
                      <span>Hi, {userDetails?.profile?.firstName}</span>
                    ) : null}
                  </Text>
                </Dropdown>
              ) : null}
            </Col>
            <Col>
              <IoMdCart
                style={{
                  color: theme.token.colorTertiary,
                  fontSize: 24,
                  cursor: "pointer",
                }}
                onClick={() => navigate("/cart")}
              />
            </Col>
          </Col>
        ) : null}
        <Col
          span={24}
          style={{
            justifyContent: "center",
            display: "flex",
            marginBottom: 10,
          }}
        >
          {!loadingBrandContextData && brandContextData.brandLogo ? (
            <Image
              onClick={() => navigate("/")}
              width={mobile ? 200 : 300}
              preview={false}
              src={brandContextData.brandLogo}
              className="headerSolutionLogo"
              style={{
                objectPosition: "center",
                maxHeight: mobile ? 100 : 125,
              }}
            />
          ) : null}
        </Col>
        <Col
          span={24}
          style={{
            justifyContent: mobile ? "center" : "initial",
            marginTop: 5,
            marginBottom: 5,
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: mobile ? "center" : "space-between",
            }}
          >
            <Row
              gutter={[12, 12]}
              style={{ justifyContent: !mobile ? "initial" : "center" }}
            >
              {/* {!brandContextData?.acceptsDonations &&
              !brandContextData?.announcements?.length ? ( */}
              {/* <Col>
                <Button
                  className="drawerButton"
                  size={mobile ? "middle" : "large"}
                  type="secondary"
                  style={{
                    fontWeight: "bold",
                    backgroundColor: theme.token.colorSecondary,
                    color: theme.token.colorPrimary,
                  }}
                  onClick={() => {
                    if (location.pathname === "/") {
                      if (
                        (!brandContextData.isChain && stadiums.length) ||
                        (brandContextData.isChain &&
                          featuredItems?.length &&
                          locationSelected)
                      ) {
                        executeScroll();
                      }
                    } else {
                      navigate("/", {
                        state: { navigateToOrders: "true" },
                      });
                    }
                  }}
                >
                  Order Now
                </Button>
              </Col> */}
              {/* // ) : null} */}

              {!loadingBrandContextData && brandContextData?.showAboutButton ? (
                <Col>
                  <Button
                    size={mobile ? "middle" : "large"}
                    type="primary"
                    onClick={() => navigate("/about-us")}
                    style={{
                      color: theme.token.colorSecondary,
                      border: `solid ${theme.token.colorSecondary} 1px`,
                    }}
                  >
                    About Us
                  </Button>
                </Col>
              ) : null}
              {!loadingBrandContextData &&
              brandContextData?.announcements?.length > 0 ? (
                <Col>
                  <Button
                    size={mobile ? "middle" : "large"}
                    type="primary"
                    onClick={() =>
                      setShowAnnouncementDrawer(!showAnnouncementDrawer)
                    }
                    style={{
                      color: "white",
                      border: `solid ${theme.token.colorSecondary} 1px`,
                      backgroundColor: theme.token.colorSecondary,
                    }}
                  >
                    Announcements
                  </Button>
                </Col>
              ) : null}

              {!loadingBrandContextData && brandContextData.acceptsDonations ? (
                <Col>
                  <Button
                    size={mobile ? "middle" : "large"}
                    type="primary"
                    style={{
                      color: theme.token.colorSecondary,
                      border: `solid ${theme.token.colorSecondary} 1px`,
                    }}
                    onClick={() => {
                      if (
                        brandContextData._id ===
                          "6463e78850ef8a1188c12dce-ottawa-dragonboat" ||
                        brandContextData._id === "66a8023aeb802bf5bf7b0096"
                      ) {
                        navigate("/donate");
                      } else {
                        setShowDrawer(!showDrawer);
                      }
                    }}
                  >
                    {brandContextData.donationButtonTitle
                      ? brandContextData.donationButtonTitle
                      : "Donate"}
                  </Button>
                </Col>
              ) : null}
            </Row>
            {!mobile ? (
              <div style={{ display: "flex", alignItems: "center" }}>
                {enableAccounts && !authData ? (
                  <Button
                    type="primary"
                    size={mobile ? "middle" : "large"}
                    style={{
                      marginRight: 20,
                      color: "white",
                      border: `solid ${theme.token.colorSecondary} 1px`,
                      backgroundColor: theme.token.colorSecondary,
                    }}
                    onClick={() => {
                      navigate("/login");
                    }}
                  >
                    Login
                  </Button>
                ) : null}
                {authData && enableAccounts ? (
                  <Dropdown
                    menu={{
                      items: [
                        {
                          key: "1",
                          label: (
                            <div onClick={() => navigate("/user-account")}>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <IoMdPerson
                                  style={{
                                    fontSize: 16,
                                    paddingRight: 5,
                                    color: "white",
                                  }}
                                />
                                <Text
                                  style={{
                                    fontWeight: 600,
                                    fontSize: 14,
                                    color: "white",
                                  }}
                                >
                                  Account
                                </Text>
                              </div>
                            </div>
                          ),
                        },
                        {
                          key: "2",
                          label: (
                            <div onClick={() => logoutUser()}>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <IoMdLogOut
                                  style={{
                                    fontSize: 16,
                                    paddingRight: 5,
                                    color: "white",
                                  }}
                                />
                                <Text
                                  style={{
                                    fontWeight: 600,
                                    fontSize: 14,
                                    color: "white",
                                  }}
                                >
                                  Logout
                                </Text>
                              </div>
                            </div>
                          ),
                        },
                      ],
                    }}
                    dropdownRender={(menu) => (
                      <div>
                        {React.cloneElement(menu, {
                          style: {
                            boxShadow: "none",
                            backgroundColor: theme.token.colorSecondary,
                          },
                        })}
                      </div>
                    )}
                  >
                    <Button
                      type="primary"
                      size={mobile ? "middle" : "large"}
                      style={{
                        marginRight: 20,
                        color: "white",
                        border: `solid ${theme.token.colorSecondary} 1px`,
                        backgroundColor: theme.token.colorSecondary,
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      {userDetails?.profile?.firstName ? (
                        <span style={{ marginRight: 12 }}>
                          Hi, {userDetails?.profile?.firstName}
                        </span>
                      ) : null}
                      <IoIosArrowDown
                        style={{
                          color: "white",
                          fontSize: 18,
                        }}
                      />
                    </Button>
                  </Dropdown>
                ) : null}

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => navigate("/cart")}
                >
                  <IoMdCart
                    style={{
                      color: theme.token.colorTertiary,
                      fontSize: mobile ? 24 : 28,
                    }}
                  />
                </div>
              </div>
            ) : null}
          </div>
        </Col>
        {brandContextData?.acceptsDonations &&
        brandContextData?.announcements?.length &&
        mobile ? (
          <Col
            span={24}
            style={{
              justifyContent: "center",
              display: "flex",
              marginTop: 5,
              marginBottom: 5,
            }}
          >
            <Button
              size={mobile ? "middle" : "large"}
              type="secondary"
              style={{
                fontWeight: "bold",
                backgroundColor: theme.token.colorSecondary,
                color: theme.token.colorPrimary,
              }}
              onClick={() => {
                if (location.pathname === "/") {
                  if (
                    (!brandContextData.isChain && stadiums.length) ||
                    (brandContextData.isChain &&
                      featuredItems?.length &&
                      locationSelected)
                  ) {
                    executeScroll();
                  }
                } else {
                  navigate("/", { state: { navigateToOrders: "true" } });
                }
              }}
            >
              Order Now
            </Button>
          </Col>
        ) : null}
        <Col span={24}>
          <AnnouncementDrawer
            showAnnouncementDrawer={showAnnouncementDrawer}
            brandContextData={brandContextData}
            setShowAnnouncementDrawer={setShowAnnouncementDrawer}
          />
          <DonateDrawer
            showDrawer={showDrawer}
            brandContextData={brandContextData}
            setShowDrawer={setShowDrawer}
            loadingBrandContextData={loadingBrandContextData}
            mobile={mobile}
          />
        </Col>
        <SelectLocationModal
          mobile={mobile}
          showSelectLocationModal={showSelectLocationModal}
          setShowSelectLocationModal={setShowSelectLocationModal}
        />
      </Row>
    </div>
  );
};

export default WhiteLabelHeader;
