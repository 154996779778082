import {
  Button,
  Col,
  Divider,
  Input,
  Layout,
  message,
  Row,
  Spin,
  Typography,
  notification,
  Image,
  Breadcrumb,
  theme,
  Modal,
} from "antd";
import { Content } from "antd/lib/layout/layout";
import React from "react";
import { useEffect, useState } from "react";

// TODO Delivery Addition
// import { geocodeByAddress } from "react-google-places-autocomplete";
import { useLocation, useNavigate } from "react-router-dom";
// TODO Delivery Addition
// import CheckoutAddressSelection from "../components/checkout/CheckoutAddressSelection";
import CheckoutInHouseSelection from "../components/checkout/CheckoutInHouseSelection";
import ContactInformation from "../components/checkout/ContactInformation";
import OrderBreakdown from "../components/checkout/OrderBreakdown";
import TakeoutMethodSelection from "../components/checkout/TakeoutMethodSelection";
import TippingSelection from "../components/checkout/TippingSelection";
import EditItemSection from "../components/checkout/EditItemSection";
import AllergySection from "../components/checkout/AllergySection";
import {
  capitalizeFirstLetter,
  containsLetters,
  encodeParamString,
  getCoords,
  rfx_v2_xnew_transformOrderObject,
  screenWidths,
  validateEmail,
  isQuantitiesWithinMax,
  convertToStandardPhoneNumber,
  formatPrice,
} from "../const/const_functions";
import useQuery from "../hooks/useQuery";
import useWindowDimensions from "../hooks/useWindowDimensions";
import { useCarts } from "../contexts/Carts";
import { isSafari } from "react-device-detect";
import { useServer } from "../contexts/Server";
import { useBrandContext } from "../contexts/Brand";
import moment from "moment-timezone";
import {
  ApplePay,
  CreditCard,
  GooglePay,
  PaymentForm,
} from "react-square-web-payments-sdk";
import { useFirebase } from "../contexts/Firebase";
import { logEvent } from "firebase/analytics";
import { useAuth } from "../contexts/Auth";
import PointRedemption from "../components/checkout/PointRedemption";
const { useToken } = theme;

const Checkout = () => {
  const { token } = useToken();
  const location = useLocation();
  const query = useQuery();
  const { Title, Text } = Typography;
  const { serverData } = useServer();
  const { TextArea } = Input;
  const { multiCart, setMultiCartData } = useCarts();
  const { userPoints, authData, getUserPoints, userDetails } = useAuth();
  const navigate = useNavigate();

  const [cart, setCart] = useState(null);
  const [stadiumData, setStadiumData] = useState();
  const [takeoutMethod, setTakeoutMethod] = useState("");
  const [tipPercentage, setTipPercentage] = useState(18);
  const [tipDollarAmount, setTipDollarAmount] = useState(0);
  const [tipAmount, setTipAmount] = useState(0);

  // Only used for addresses
  // const [addressSelected, setAddressSelected] = useState(userActiveAddress);
  const [tableNumber, setTableNumber] = useState(null);
  const [usePickupLocation, setUsePickupLocation] = useState(false);
  const [orderTotalWithEverything, setOrderTotalWithEverything] = useState(0);
  const [deliveryNote, setDeliveryNote] = useState("");
  const [storeNote, setStoreNote] = useState("");
  const [hstToShow, setHstToShow] = useState(0);
  const [deliveryPrice, setDeliveryPrice] = useState(0);
  const [orderSubtotal, setOrderSubtotal] = useState(0);
  const [orderTotalWithTax, setOrderTotalWithTax] = useState(0);
  const [placingOrderModal, setPlacingOrderModal] = useState(false);
  const [showAddressTooFar, setShowAddressTooFar] = useState(false);
  const [leaveAtDoor, setLeaveAtDoor] = useState("false");
  const [hasAllergies, setHasAllergies] = useState(false);
  const [allergiesNote, setAllergiesNote] = useState(null);
  const [digitalFeeObj, setDigitalFeeObj] = useState({});
  const [serviceFeeToShow, setServiceFeeToShow] = useState(0);
  const [computingBreakdown, setComputingBreakdown] = useState(true);

  const [fullName, setFullName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [enableStatusSMSUpdates, setEnableStatusSMSUpdates] = useState(true);
  const [saveInfoForNextTime, setSaveInfoForNextTime] = useState(true);

  const [inhouseSeatingType, setInhouseSeatingType] = useState("Table");
  const [instagramHandle, setInstagramHandle] = useState("");
  const [linkedInHandle, setLinkedInHandle] = useState("");
  const [formKey, setFormKey] = useState(0);

  const [placingOrder, setPlacingOrder] = useState(false);
  const stadiumChosenToCheckoutID = query.get("glb");

  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleOk = async () => {
    setIsModalOpen(false);
    try {
      const resendVerificationRes = await serverData.call(
        "USSR_resendVerificationEmail",
        { contextId: brandContextData._id }
      );
      if (resendVerificationRes?.success) {
        alert(
          `An email with a link to verify your email has been sent to ${userDetails.profile.email}.`
        );
      }
    } catch (e) {
      alert(
        "There was an error",
        "Please check the Help And Support screen for further assistance"
      );
    }
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const { brandContextData, loadingBrandContextData } = useBrandContext();
  const { firebaseAnalytics } = useFirebase();

  const [cartPointsObj, setCartPointsObj] = useState(null);
  const [pointsRedemptionAmount, setPointsRedemptionAmount] = useState(0);

  useEffect(() => {
    checkForSavedInfo();
  }, []);

  useEffect(() => {
    if (takeoutMethod && multiCart && orderSubtotal && orderTotalWithTax) {
      computePointsFields();
    }
  }, [
    takeoutMethod,
    multiCart,
    pointsRedemptionAmount,
    orderSubtotal,
    orderTotalWithTax,
  ]);

  useEffect(() => {
    if (!cartPointsObj?.canSpendPoints) {
      setPointsRedemptionAmount(0);
    }
  }, [cartPointsObj]);

  const computePointsFields = async () => {
    if (!takeoutMethod || orderSubtotal === 0) {
      return;
    }
    let orderTotal = orderTotalWithTax;
    const cartChosenToForCheckout = multiCart.find(
      (mCart) => mCart.stadiumID === stadiumChosenToCheckoutID
    );

    const dataObjToBeTransformed = {
      foodItems: cartChosenToForCheckout,
      amount: null,
      address: null,
      deliveryComment: null,
      note: null,
      deliveryOption: null,
      stadium: {
        _id: cartChosenToForCheckout.stadiumID,
        stadTypes: cartChosenToForCheckout.stadTypes,
      },
      paymentInfo: { platform: "web", appVersion: null },
      zone: null,
      clientPickup: null,
      tableNumber: null,
    };

    let transformedDataObj = null;
    transformedDataObj = rfx_v2_xnew_transformOrderObject(
      dataObjToBeTransformed
    );

    try {
      let pointsObj = await serverData.call("MIKO_calculateCartPoints", {
        contextId: brandContextData._id,
        stadiumId: cartChosenToForCheckout.stadiumID,
        subtotalBeforeTax: orderSubtotal,
        subtotalAfterTax: orderTotal,
        dvOption: takeoutMethod,
        foodsOrdered: transformedDataObj.foodsOrdered
          ? transformedDataObj.foodsOrdered
          : [],
        merchItems: transformedDataObj.merchItems
          ? transformedDataObj.merchItems
          : [],
        pointsRedeemed: pointsRedemptionAmount ?? 0,
      });
      setCartPointsObj(pointsObj);
    } catch (e) {
      console.log(e);
    }
  };

  const checkForSavedInfo = () => {
    const currentDate = new Date();

    if (!cart) {
      return;
    }

    const checkDate = moment(cart.createdAt).add(1, "days");

    if (moment(currentDate).isAfter(checkDate)) {
      return;
    }

    const userInfoForQuickCheckout = JSON.parse(
      localStorage.getItem("userInfoForQuickCheckout")
    );

    if (!userInfoForQuickCheckout) return;

    // Extracting relevant information from userInfoForQuickCheckout
    const { tableNumber, dateOfLastCheckout } = userInfoForQuickCheckout;
    if (dateOfLastCheckout) {
      const check4HoursMore = moment(dateOfLastCheckout).add(4, "hours");
      if (!moment(currentDate).isAfter(check4HoursMore)) {
        setTableNumber(tableNumber);
      }
    }
  };

  useEffect(() => {
    if (serverData) {
      getStadiumData();
    }
  }, [serverData, multiCart]);

  useEffect(() => {
    if (stadiumData && stadiumData.inhouseSeatingType) {
      setInhouseSeatingType(stadiumData.inhouseSeatingType);
    }
  }, [stadiumData]);

  useEffect(() => {
    setFormKey((prevKey) => prevKey + 1);
  }, [orderTotalWithEverything]);

  useEffect(() => {
    if (placingOrderModal) {
      window.scrollTo(0, 0);
    }
  }, [placingOrderModal]);

  // Not used for now until delivery
  // useEffect(() => {
  //   if (addressSelected && takeoutMethod === "delivery") {
  //     getStadiumDataWithAddress(addressSelected);
  //   }
  // }, [addressSelected, takeoutMethod]);

  // Not used for now until delivery
  // const getStadiumDataWithAddress = async (addressSelected) => {
  //   const stadiumChosenToCheckoutID = query.get("glb");
  //   const userCoords = await getCoords(addressSelected);

  //   const newStadiumData = await server.call("USSR_v2_getOneStadium", {
  //     stadiumId: stadiumChosenToCheckoutID,
  //     userCoords,
  //   });

  //   if (!newStadiumData.nowDoDelivery) {
  //     message.info(
  //       `Sorry, but the address you inputted is too far away from ${newStadiumData.name} to receive delivery.`
  //     );
  //     setShowAddressTooFar(true);
  //   }

  //   const newCart = multiCart.find(
  //     (mCart) => mCart.stadiumID === cart.stadiumID
  //   );
  //   setCart(newCart);
  // };

  const getStadiumData = async () => {
    const stadiumData = await serverData.call(
      "USSR_getOneStadium",
      stadiumChosenToCheckoutID
    );

    const { nowDoDelivery, nowDoInHouse, nowDoPickup } = stadiumData;

    if (!nowDoDelivery && !nowDoPickup && !nowDoInHouse) {
      navigate("/cart");
      message.info("Sorry this business is currently closed");
      return;
    }

    if (stadiumData.stadTypes[0] === "STT_VENUE") {
      setTakeoutMethod("admission");
    } else if (!nowDoDelivery && !nowDoPickup && nowDoInHouse) {
      setTakeoutMethod("inhouse");
    } else if (!nowDoDelivery && nowDoPickup && !nowDoInHouse) {
      setTakeoutMethod("pickup");
    } else if (nowDoDelivery && !nowDoPickup && !nowDoInHouse) {
      setTakeoutMethod("delivery");
    }

    setStadiumData(stadiumData);

    const clientPickupEnabled = stadiumData?.inhouseOrderOptions?.clientPickup;
    const tableNumberEnabled = stadiumData?.inhouseOrderOptions?.inSeat;

    if (!clientPickupEnabled && !tableNumberEnabled) {
      setUsePickupLocation(true);
    } else if (clientPickupEnabled && !tableNumberEnabled) {
      setUsePickupLocation(true);
    }

    const newCart = multiCart.find(
      (mCart) => mCart.stadiumID === stadiumChosenToCheckoutID
    );
    setCart(newCart);
  };

  const { width } = useWindowDimensions();
  let mobileSize = false;
  if (screenWidths.md > width) {
    mobileSize = true;
  }

  const checkForValidInfo = (quickpay) => {
    if (takeoutMethod === "inhouse") {
      const clientPickupEnabled =
        stadiumData?.inhouseOrderOptions?.clientPickup;
      const tableNumberEnabled = stadiumData?.inhouseOrderOptions?.inSeat;

      let tableNumberHere = tableNumber;
      let usePickupLocationHere = usePickupLocation;

      if (!clientPickupEnabled && tableNumberEnabled) {
        if (!tableNumberHere) {
          message.error(
            `Please enter your ${inhouseSeatingType.toLowerCase()} number.`
          );
          return true;
        }
      }

      if (clientPickupEnabled && tableNumberEnabled) {
        if (!tableNumberHere && !usePickupLocationHere) {
          message.error(
            `Please enter your ${inhouseSeatingType.toLowerCase()} number or enable client pickup.`
          );
          return true;
        }
      }
    }

    if (quickpay) {
      return false;
    }

    // Not used until delivery is added
    // if (takeoutMethod === "delivery") {
    //   if (!addressSelected || Object.keys(addressSelected).length === 0) {
    //     return true;
    //   }

    //   if (showAddressTooFar) {
    //     return true;
    //   }
    // }

    if (!firstName || !lastName || !phoneNumber || !email) {
      if (!firstName) {
        message.error(`Please input your first name.`);
      } else if (!lastName) {
        message.error(`Please input your last name.`);
      } else if (!phoneNumber) {
        message.error(`Please input your phone number.`);
      } else if (!email) {
        message.error(`Please input your email.`);
      }
      return true;
    }

    if (containsLetters(phoneNumber)) {
      message.error(`Please input a valid phone number.`);
      return true;
    }

    if (email && !validateEmail(email)) {
      message.error(`Please input a valid email.`);
      return true;
    }

    if (phoneNumber.length < 10) {
      message.error(`Please input a valid phone number.`);
      return true;
    }

    return false;
  };

  const orderErrorNotification = (placement, description) => {
    notification.info({
      message: "Order Validation Error",
      description,
      placement,
    });
  };

  const prepareOrderObj = async ({ quickpay = false, payToken = null }) => {
    const dateOfLastCheckout = new Date();
    if (!quickpay) {
      if (saveInfoForNextTime) {
        let userInfoForQuickCheckout = {
          firstName,
          lastName,
          phoneNumber,
          email,
          tableNumber,
          dateOfLastCheckout,
        };
        localStorage.setItem(
          "userInfoForQuickCheckout",
          JSON.stringify(userInfoForQuickCheckout)
        );
      } else {
        localStorage.removeItem("userInfoForQuickCheckout");
      }
    }

    let paymentInfo = {
      totalAmount: orderTotalWithEverything,
      hst: hstToShow,
      deliveryFee: takeoutMethod === "delivery" ? deliveryPrice : null,
      orderAmount: orderSubtotal,
      appVersion: null,
      platform: "web",
    };

    let region = null;

    // TODO Delivery Addition
    // if (takeoutMethod === "delivery") {
    //   try {
    //     const coords = await getCoords(addressSelected);
    //     region = await server.call("USSR_getRegionFromCoordinate", coords);
    //   } catch (e) {
    //     message.error("Your address is outside our delivery regions.", 5);
    //     return;
    //   }
    // }

    // let deliveryComment = deliveryNote;
    // if (leaveAtDoor === "true") {
    //   deliveryComment = deliveryNote
    //     ? `${deliveryNote}, *Contactless* Leave at door`
    //     : "*Contactless* Leave at door";
    // }

    let storeInstructions = storeNote;
    if (allergiesNote) {
      storeInstructions = storeNote
        ? `${storeNote}, *Allergies: ${allergiesNote}*`
        : `*Allergies: ${allergiesNote}*`;
    }

    if (instagramHandle) {
      storeInstructions = storeInstructions
        ? `${storeInstructions}, *Instagram: ${instagramHandle}*`
        : `*Instagram: ${instagramHandle}*`;
    }

    if (linkedInHandle) {
      storeInstructions = storeInstructions
        ? `${storeInstructions}, *LinkedIn: ${linkedInHandle}*`
        : `*LinkedIn: ${linkedInHandle}*`;
    }

    const dataObjToBeTransformed = {
      foodItems: cart,
      amount: orderTotalWithEverything,
      // address: takeoutMethod === "delivery" ? addressSelected : null,
      address: null,
      // deliveryComment: takeoutMethod === "delivery" ? deliveryComment : null,
      deliveryComment: null,
      note: storeInstructions,
      deliveryOption: takeoutMethod,
      stadium: stadiumData,
      paymentInfo,
      zone: region,
      clientPickup: usePickupLocation,
      tableNumber,
    };

    let transformedDataObj = null;
    transformedDataObj = rfx_v2_xnew_transformOrderObject(
      dataObjToBeTransformed
    );

    if (!transformedDataObj) {
      message.error("Something went wrong, please try again.", 5);
      return;
    }

    // if (!userDetails) {
    //   transformedDataObj.guestInfo = {
    //     name: `${firstName} ${lastName}`,
    //     phoneNumber: phoneNumber,
    //     email: email,
    //   };
    //   transformedDataObj.guestCheckout = true;
    // } else {
    //   transformedDataObj.guestInfo = null;
    //   transformedDataObj.guestCheckout = false;
    // }

    if (quickpay) {
      if (payToken.details.method === "Apple Pay") {
        if (
          !payToken?.details?.shipping?.contact?.givenName ||
          !payToken?.details?.shipping?.contact?.familyName ||
          !payToken?.details?.shipping?.contact?.phone ||
          !payToken?.details?.shipping?.contact?.email
        ) {
          message.Error(
            "There was an error with your Apple Pay Payment. Please use the manual input method below."
          );
          throw new Error("There was an error with Apple Pay");
        }

        transformedDataObj.guestInfo = {
          name: `${payToken.details.shipping.contact.givenName} ${payToken.details.shipping.contact.familyName} `,
          phoneNumber: convertToStandardPhoneNumber(
            payToken.details.shipping.contact.phone
          ),
          email: payToken.details.shipping.contact.email,
        };
      } else if (payToken.details.method === "Google Pay") {
        if (
          !payToken?.details?.billing?.givenName ||
          !payToken?.details?.billing?.familyName ||
          !payToken?.details?.billing?.phone ||
          !payToken?.details?.billing?.email
        ) {
          message.Error(
            "There was an error with your Google Pay Payment. Please use the manual input method below."
          );
          throw new Error("There was an error with Google Pay");
        }

        transformedDataObj.guestInfo = {
          name: `${payToken.details.billing.givenName} ${payToken.details.billing.familyName} `,
          phoneNumber: convertToStandardPhoneNumber(
            payToken.details.billing.phone
          ),
          email: payToken.details.billing.email,
        };
      }
    } else {
      // Forced for now
      transformedDataObj.guestInfo = {
        name: `${firstName} ${lastName}`,
        phoneNumber: phoneNumber,
        email: email,
      };
    }

    if (!authData) {
      transformedDataObj.guestCheckout = true;
    } else {
      transformedDataObj.guestCheckout = false;
      transformedDataObj.guestInfo = {
        name: "",
        phoneNumber: "",
        email: "",
      };
    }

    // Attach sms updates
    transformedDataObj.enableSMSUpdates = enableStatusSMSUpdates;

    transformedDataObj.contextId = brandContextData._id;
    transformedDataObj.pointsToRedeem = pointsRedemptionAmount;

    const orderID = await placeTheOrder(transformedDataObj);
    return orderID;
  };

  const placeTheOrder = async (orderObj) => {
    // TODO Delivery addition
    // if (orderObj.address && takeoutMethod === "delivery") {
    //   let results = null;
    //   if (orderObj.address.guestAddressLabel) {
    //     results = await geocodeByAddress(orderObj.address.longLabel);
    //   } else if (orderObj.address.label) {
    //     results = await geocodeByAddress(orderObj.address.label);
    //   } else if (orderObj.address.formattedAddress) {
    //     results = await geocodeByAddress(orderObj.address.formattedAddress);
    //   }

    //   const googleDetails = results[0];

    //   const lat = googleDetails.geometry.location.lat();
    //   const lng = googleDetails.geometry.location.lng();

    //   const modifiedLocation = {
    //     lat,
    //     lng,
    //   };
    //   const moddedGoogleDetails = { ...googleDetails, modifiedLocation };

    //   orderObj.address = moddedGoogleDetails.formatted_address;
    // }

    try {
      const res = await serverData.call("rfx_v2_xmod_addOrder", orderObj);
      return res;
    } catch (err) {
      if (err.reason) {
        orderErrorNotification("top", err.reason);
        return;
      } else {
        orderErrorNotification(
          "bottom",
          "Unknown error occured, please try again. If the issue persists, please contact support."
        );
        return;
      }
    }
  };
  const createTheOrder = async ({ quickpay = false, payToken = null }) => {
    setPlacingOrderModal(true);

    if (authData && !userDetails?.profile?.emailVerified) {
      setIsModalOpen(true);
      setPlacingOrderModal(false);
      return;
    }

    if (checkForValidInfo(quickpay)) {
      setPlacingOrderModal(false);
      return;
    }

    if (cart.stadTypes.includes("STT_VENUE")) {
      const quantitiesData = isQuantitiesWithinMax(cart);
      if (quantitiesData) {
        message.open({
          type: "warning",
          content: `You can only purchase a maximum of ${quantitiesData.evAdmisCustMax} ${quantitiesData.evAdmisName} for this event, please adjust your cart amount and try again`,
        });
        setPlacingOrderModal(false);
        return;
      }
    }

    const orderID = await prepareOrderObj({ quickpay, payToken });

    // If order amount is equal to zero. Don't go to the payment screen.
    if (orderTotalWithEverything === "0.00") {
      setPlacingOrder(true);
      let paymentXYZObj = {
        paymentXType: "xpayment-at-store",
      };

      const pointRedemptionDollars =
        pointsRedemptionAmount * cartPointsObj.multiplyRedemptionRate;

      try {
        await serverData.call("USSR_makePayment", {
          orderID,
          userAppCalculatedOrder: orderTotalWithEverything,
          userAppTipped: tipAmount,
          paymentXYZObj,
          verifiedBuyer: null,
        });

        //Remove current cart
        const newMultiCart = multiCart.filter(
          (cart) => cart.stadiumID !== location.state.cart.stadiumID
        );
        setMultiCartData(newMultiCart);
      } catch (error) {
        console.log(error);
        setPlacingOrder(false);
        setPlacingOrderModal(false);
        return;
      }

      let order = null;
      try {
        order = await serverData.call("fetchOrderById", orderID);
      } catch (e) {
        console.log(e);
        setPlacingOrder(false);
        setPlacingOrderModal(false);
        return;
      }
      const { stadium, orderNo } = order;

      let items = [];
      if (order?.ORDER_QTYPE === "QVENUE") {
        order?.eventAdmissions?.forEach((admission) => {
          items.push({
            item_id: admission?.evId,
            item_name: admission?.evName,
          });
        });
      }

      if (order?.ORDER_QTYPE === "QFOOD") {
        order?.v2_foodCart?.forEach((foodItem) => {
          items.push({ item_id: foodItem?._id, item_name: foodItem?.name });
        });
      }

      if (order?.ORDER_QTYPE === "QRETAIL") {
        order?.omprodItems?.forEach((retailItem) => {
          items.push({
            item_id: retailItem?.retailItemId,
            item_name: retailItem?.omPtitle,
          });
        });
      }

      let payCurrency = order?.paymentCurrency;
      if (!order?.paymentCurrency) {
        payCurrency = "CAD";
      }

      if (firebaseAnalytics) {
        logEvent(firebaseAnalytics, "purchase", {
          orderNo: order?.orderNo,
          tip: order?.tipping?.amount,
          transaction_id: String(orderNo),
          currency: payCurrency,
          value: Number(order?.paymentInfo?.orderAmount), // Total Revenue
          tax: Number(order?.paymentInfo?.hst),
          items,
        });
      }

      setPlacingOrder(false);
      setPlacingOrderModal(false);
      navigate(`/myorders/${orderNo}`, {
        state: {
          order,
          stadium,
        },
      });

      return;
    } else {
      if (quickpay) {
        makeQuickPayment({ orderID, payToken });
      } else {
        navigate("/pay", {
          state: {
            orderID: orderID,
            orderTotalWithTax: orderTotalWithTax,
            tipAmount: tipAmount,
            orderTotalWithEverything: orderTotalWithEverything,
            cart: cart,
            firstName: firstName,
            lastName: lastName,
            email: email,
            phoneNumber: phoneNumber,
            enableStatusSMSUpdates: enableStatusSMSUpdates,
          },
        });
      }
    }
  };

  const makeQuickPayment = async (fieldsReqForPayment) => {
    const { orderID, payToken } = fieldsReqForPayment;
    let paymentXYZObj = {
      paymentXType: "xpayment-entered-card",
      nonce: payToken.token,
    };

    try {
      await serverData.call("USSR_makePayment", {
        orderID,
        userAppCalculatedOrder: orderTotalWithEverything,
        userAppTipped: tipAmount,
        paymentXYZObj,
        verifiedBuyer: null,
      });
    } catch (error) {
      if (error.reason) {
        notification.error({
          message: error.reason,
        });
        setPlacingOrderModal(false);
        return;
      } else {
        notification.error({
          message: "Something went wrong",
        });
        setPlacingOrderModal(false);
        return;
      }
    }

    //Remove current cart
    const newMultiCart = multiCart.filter(
      (cart) => cart.stadiumID !== stadiumChosenToCheckoutID
    );
    setMultiCartData(newMultiCart);

    let order = null;

    try {
      order = await serverData.call("fetchOrderById", orderID);
    } catch (e) {
      console.log(e);
      setPlacingOrderModal(false);
      return;
    }
    const { stadium, orderNo } = order;

    let items = [];
    if (order?.ORDER_QTYPE === "QVENUE") {
      order?.eventAdmissions?.forEach((admission) => {
        items.push({ item_id: admission?.evId, item_name: admission?.evName });
      });
    }

    if (order?.ORDER_QTYPE === "QFOOD") {
      order?.v2_foodCart?.forEach((foodItem) => {
        items.push({ item_id: foodItem?._id, item_name: foodItem?.name });
      });
    }

    if (order?.ORDER_QTYPE === "QRETAIL") {
      order?.omprodItems?.forEach((retailItem) => {
        items.push({
          item_id: retailItem?.retailItemId,
          item_name: retailItem?.omPtitle,
        });
      });
    }

    let payCurrency = order?.paymentCurrency;
    if (!order?.paymentCurrency) {
      payCurrency = "CAD";
    }

    if (firebaseAnalytics) {
      logEvent(firebaseAnalytics, "purchase", {
        orderNo: order?.orderNo,
        tip: order?.tipping?.amount,
        transaction_id: String(orderNo),
        currency: payCurrency,
        value: Number(order?.paymentInfo?.orderAmount), // Total Revenue
        tax: Number(order?.paymentInfo?.hst),
        items,
      });
    }

    setPlacingOrderModal(false);
    getUserPoints(brandContextData._id);
    navigate(`/myorders/${orderNo}`, {
      state: {
        order,
        stadium,
      },
    });
  };

  // If cart has not been retrieved as yet
  if (!cart) {
    return null;
  }

  const showBusinessOfferings = (name, _id) => {
    if (takeoutMethod === "admission") {
      navigate("/", false);
    } else {
      const stadiumNameDashes = name.replaceAll(" ", "-");
      let encodedStoreId = encodeParamString(_id);
      const newStoreUrl = `/order/${stadiumNameDashes}?glb=${encodedStoreId}`;
      navigate(newStoreUrl);
    }
  };

  let trueCount = 0;
  if (stadiumData) {
    const { nowDoDelivery, nowDoPickup, nowDoInHouse } = stadiumData;
    trueCount = [nowDoDelivery, nowDoPickup, nowDoInHouse].filter(
      (value) => value
    ).length;
  }

  let isGhostEventStores = false;
  let headerText = `Your ${
    takeoutMethod === "inhouse"
      ? "In-House"
      : capitalizeFirstLetter(takeoutMethod)
  } Order from`;
  if (
    !loadingBrandContextData &&
    brandContextData.ghostEventStores &&
    brandContextData.ghostEventStores === true &&
    stadiumData?.stadTypes.includes("STT_VENUE")
  ) {
    isGhostEventStores = true;
    headerText = "Your Order";
  }

  const descriptionLabel =
    brandContextData._id === "64b80d8a5bdf376def855b1e"
      ? "How can the Beyond Networking community help you?"
      : `Notes for ${cart.stadiumName}`;

  return (
    <Layout style={{ backgroundColor: "white" }}>
      <Spin tip="Loading..." spinning={placingOrderModal}>
        <Content
          style={{
            minHeight: "100vh",
            marginBottom: 100,
            marginTop: 20,
          }}
        >
          <Modal
            title="Account Verification Required"
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={[
              <Button key="back" onClick={handleCancel}>
                Return
              </Button>,
              <Button
                key="submit"
                type="primary"
                style={{ backgroundColor: "black", color: "white" }}
                onClick={handleOk}
              >
                Re-send Email
              </Button>,
            ]}
          >
            <p>
              Please verify your email before placing orders. Didn't receive an
              email? Click re-send email below to receive a new link.
            </p>
          </Modal>
          <Row style={{ marginBottom: 20 }}>
            <Col md={{ span: 16, offset: 4 }} xs={{ span: 22, offset: 1 }}>
              <Breadcrumb
                items={[
                  {
                    title: (
                      <a
                        onClick={() =>
                          showBusinessOfferings(
                            cart.stadiumName,
                            cart.stadiumID
                          )
                        }
                      >
                        {takeoutMethod === "admission" ? "Home" : "Menu"}
                      </a>
                    ),
                  },
                  { title: "Checkout" },
                ]}
                style={{ marginBottom: 6 }}
              />
            </Col>
          </Row>

          <Row style={{ marginTop: 20 }}>
            <Col md={{ span: 16, offset: 4 }} xs={{ span: 22, offset: 1 }}>
              {trueCount === 1 ? (
                <>
                  {brandContextData._id === "64ad6cccf9ce9655bf2f36f9" &&
                  (stadiumChosenToCheckoutID === "PuFJtkByNSq3Md9ua" ||
                    stadiumChosenToCheckoutID === "zanDBkduyFHBm77rA") ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginBottom: 18,
                        fontWeight: "bold",
                      }}
                    >
                      <Text
                        style={
                          mobileSize
                            ? { fontSize: 18, textAlign: "center" }
                            : { fontSize: 18 }
                        }
                        justify={"center"}
                      >
                        NOTE: The Ottawa Team Shop will close for ordering at
                        the end of the 2nd period.
                      </Text>
                    </div>
                  ) : null}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Text
                      style={mobileSize ? { fontSize: 18 } : { fontSize: 18 }}
                      justify={"center"}
                    >
                      Your Order from
                    </Text>
                  </div>

                  {cart?.stadiumLogoUrl ? (
                    <Row
                      justify={"center"}
                      style={{ marginTop: 10, marginBottom: 10 }}
                    >
                      <Image
                        src={cart.stadiumLogoUrl}
                        style={{ width: mobileSize ? 150 : 150 }}
                        preview={false}
                      />
                    </Row>
                  ) : (
                    <Row
                      justify={mobileSize ? "center" : "flex-start"}
                      style={{ marginBottom: 20 }}
                    >
                      <Title
                        style={
                          mobileSize
                            ? { fontSize: 22, marginTop: 0 }
                            : { fontSize: "default" }
                        }
                      >
                        {cart.stadiumName}
                      </Title>
                    </Row>
                  )}
                </>
              ) : (
                <>
                  {!isGhostEventStores ? (
                    <Row justify="center">
                      <Col xl={12} lg={18} md={22} xs={24}>
                        <Title
                          style={
                            mobileSize
                              ? { fontSize: 22, marginTop: 0 }
                              : { fontSize: "default" }
                          }
                        >
                          {cart.stadiumName}
                        </Title>
                      </Col>
                    </Row>
                  ) : null}
                </>
              )}

              <EditItemSection
                cart={cart}
                multiCart={multiCart}
                setMultiCartData={setMultiCartData}
              />
              {trueCount > 1 && stadiumData.stadTypes[0] !== "STT_VENUE" ? (
                <>
                  <TakeoutMethodSelection
                    stadiumData={stadiumData}
                    setTakeoutMethod={setTakeoutMethod}
                    takeoutMethod={takeoutMethod}
                  />
                </>
              ) : null}
              {takeoutMethod === "inhouse" && (
                <CheckoutInHouseSelection
                  stadiumData={stadiumData}
                  inhouseSeatingType={inhouseSeatingType}
                  tableNumber={tableNumber}
                  setTableNumber={setTableNumber}
                  usePickupLocation={usePickupLocation}
                  setUsePickupLocation={setUsePickupLocation}
                  inhouseOrderOptions={
                    stadiumData?.inhouseOrderOptions
                      ? stadiumData?.inhouseOrderOptions
                      : null
                  }
                />
              )}
              {/* {brandContextData._id === "64ad6cccf9ce9655bf2f36f9" &&
              (stadiumChosenToCheckoutID === "PuFJtkByNSq3Md9ua" ||
                stadiumChosenToCheckoutID === "zanDBkduyFHBm77rA") ? (
                <Row justify="center">
                  <Col xl={12} lg={18} md={22} xs={24}>
                    <p style={{ fontWeight: "bold", fontSize: 18 }}>
                      Bonus Item Earned!
                    </p>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        borderWidth: 1,
                        borderStyle: "solid",
                        paddingLeft: 20,
                        paddingRight: 20,
                        borderRadius: 8,
                        borderColor: "lightgray",
                        alignItems: "center",
                      }}
                    >
                      <p style={{ fontSize: 16, paddingRight: 12 }}>
                        1 x AP '23 Prime Unstructured Adjustable Cap
                      </p>
                      <p style={{ fontWeight: "bold", fontSize: 16 }}>Free</p>
                    </div>
                    <p style={{ color: "gray", marginBottom: 0 }}>
                      *Your bonus item will not appear on your receipt, but will
                      be delivered to you with the rest of your order.
                    </p>
                  </Col>
                </Row>
              ) : null} */}

              {stadiumData?._id !== "f6fquSWYZXQqtmS2t" ? (
                <Row justify="center" style={{ marginTop: 20 }}>
                  <Col xl={12} lg={18} md={22} xs={24}>
                    <Text style={{ fontWeight: "bold", fontSize: 18 }}>
                      {descriptionLabel}
                    </Text>
                    <TextArea
                      rows={3}
                      placeholder="Store Instructions (Max 140 Characters)"
                      maxLength={140}
                      value={storeNote}
                      onChange={(e) => setStoreNote(e.target.value)}
                    />
                  </Col>
                </Row>
              ) : null}

              {takeoutMethod !== "admission" &&
              stadiumData.stadTypes[0] !== "STT_RETAIL" &&
              stadiumData?._id !== "f6fquSWYZXQqtmS2t" ? (
                <AllergySection
                  hasAllergies={hasAllergies}
                  setHasAllergies={setHasAllergies}
                  allergiesNote={allergiesNote}
                  setAllergiesNote={setAllergiesNote}
                />
              ) : null}

              {takeoutMethod !== "admission" &&
              (orderTotalWithEverything !== "0.00" || pointsRedemptionAmount) &&
              brandContextData._id !== "64ee4ca45e7521f5e54045fc" &&
              brandContextData._id !== "64ad6cccf9ce9655bf2f36f9" &&
              brandContextData._id !== "655d24864a91a07f4e8fb68b" &&
              stadiumData?._id !== "f6fquSWYZXQqtmS2t" ? (
                <TippingSelection
                  setTipPercentage={setTipPercentage}
                  tipPercentage={tipPercentage}
                  tipDollarAmount={tipDollarAmount}
                  setTipDollarAmount={setTipDollarAmount}
                  takeoutMethod={takeoutMethod}
                />
              ) : null}

              {userPoints && cartPointsObj?.canSpendPoints ? (
                <PointRedemption
                  setPointsRedemptionAmount={setPointsRedemptionAmount}
                  pointsRedemptionAmount={pointsRedemptionAmount}
                  cartPointsObj={cartPointsObj}
                  userPoints={userPoints}
                />
              ) : null}

              <OrderBreakdown
                cartPointsObj={cartPointsObj}
                userPoints={userPoints}
                pointsRedemptionAmount={pointsRedemptionAmount}
                stadiumData={stadiumData}
                stadiumChosenToCheckoutID={location.state.cart.stadiumID}
                takeoutMethod={takeoutMethod}
                tipPercentage={tipPercentage}
                tipDollarAmount={tipDollarAmount}
                // TODO Delivery addition
                // addressSelected={addressSelected}
                orderTotalWithEverything={orderTotalWithEverything}
                setOrderTotalWithEverything={setOrderTotalWithEverything}
                hstToShow={hstToShow}
                setHstToShow={setHstToShow}
                deliveryPrice={deliveryPrice}
                setDeliveryPrice={setDeliveryPrice}
                orderSubtotal={orderSubtotal}
                setOrderSubtotal={setOrderSubtotal}
                tipAmount={tipAmount}
                setTipAmount={setTipAmount}
                orderTotalWithTax={orderTotalWithTax}
                setOrderTotalWithTax={setOrderTotalWithTax}
                showAddressTooFar={showAddressTooFar}
                setShowAddressTooFar={setShowAddressTooFar}
                digitalFeeObj={digitalFeeObj}
                setDigitalFeeObj={setDigitalFeeObj}
                serviceFeeToShow={serviceFeeToShow}
                setServiceFeeToShow={setServiceFeeToShow}
                setComputingBreakdown={setComputingBreakdown}
                computingBreakdown={computingBreakdown}
              />

              {authData && cartPointsObj?.actualPointsEarned > 0 ? (
                <p
                  style={{ textAlign: "center", marginTop: 40 }}
                >{`Nice! You'll earn ${(cartPointsObj?.actualPointsEarned > 0
                  ? cartPointsObj?.actualPointsEarned
                  : 0
                ).toLocaleString()} ${
                  userPoints?.pointsLabel
                } on this order`}</p>
              ) : null}

              {/* NOTE Used for quickpay */}
              {orderTotalWithEverything !== "0.00" ? (
                <>
                  <Row justify="center" style={{ marginTop: 40 }}>
                    <Col xl={12} lg={18} md={22} xs={24}>
                      <div style={{ marginBottom: 12 }}>
                        <Row>
                          <Text
                            style={{
                              fontSize: 18,
                              fontWeight: "bold",
                            }}
                          >
                            Faster Checkout
                          </Text>
                        </Row>
                        <Row>
                          <Text
                            style={{
                              fontSize: 14,

                              color: "gray",
                            }}
                          >
                            Auto-fill with Google & Apple Pay
                          </Text>
                        </Row>
                      </div>
                      {brandContextData?.squareData ? (
                        <PaymentForm
                          key={formKey}
                          applicationId={brandContextData.squareData.squareId}
                          locationId={
                            brandContextData.squareData.squareLocation
                          }
                          cardTokenizeResponseReceived={(token) => {
                            const payToken = token;
                            const quickpay = true;
                            createTheOrder({ quickpay, payToken });
                          }}
                          createPaymentRequest={() => ({
                            countryCode:
                              brandContextData.squareData.countryCode,
                            currencyCode:
                              brandContextData.squareData.currencyCode,
                            requestBillingContact: true,
                            requestShippingContact: false,
                            total: {
                              amount: orderTotalWithEverything.toString(),
                              label: "Total",
                            },
                          })}
                        >
                          <div>
                            <GooglePay />
                          </div>
                          <div style={{ paddingTop: 24 }}>
                            <ApplePay />
                          </div>
                        </PaymentForm>
                      ) : null}
                    </Col>
                  </Row>
                  <Divider style={{ marginTop: 20, marginBottom: 20 }} />
                  <Row justify="center" style={{ paddingBottom: 20 }}>
                    <Col xl={12} lg={18} md={22} xs={24}>
                      <p style={{ margin: 0, fontStyle: "italic" }}>
                        Or, fill in your information below to manually fill in
                        your credit card
                      </p>
                    </Col>
                  </Row>
                </>
              ) : null}

              {orderTotalWithEverything === "0.00" ? (
                <>
                  <Row justify="center" style={{ paddingTop: 20 }}>
                    <Col xl={12} lg={18} md={22} xs={24}>
                      {/* <Divider style={{ marginTop: 60, marginBottom: 60 }} /> */}
                      <Button
                        size="large"
                        style={{
                          width: "100%",
                          backgroundColor: "white",
                          color: token.colorSecondary,
                          fontWeight: 600,
                          marginBottom: 60,
                        }}
                        disabled={computingBreakdown || placingOrder}
                        onClick={() => createTheOrder({})}
                      >
                        {placingOrder ? "Placing Order.." : "Place Order"}
                      </Button>
                    </Col>
                  </Row>
                </>
              ) : null}

              <ContactInformation
                takeoutMethod={takeoutMethod}
                fullName={fullName}
                setFullName={setFullName}
                firstName={firstName}
                setFirstName={setFirstName}
                lastName={lastName}
                setLastName={setLastName}
                phoneNumber={phoneNumber}
                setPhoneNumber={setPhoneNumber}
                email={email}
                setEmail={setEmail}
                setEnableStatusSMSUpdates={setEnableStatusSMSUpdates}
                enableStatusSMSUpdates={enableStatusSMSUpdates}
                setSaveInfoForNextTime={setSaveInfoForNextTime}
                saveInfoForNextTime={saveInfoForNextTime}
              />
              {/* TODO Delivery Addition */}
              {/* {takeoutMethod === "delivery" && (
                <CheckoutAddressSelection
                  deliveryNote={deliveryNote}
                  setDeliveryNote={setDeliveryNote}
                  addressSelected={addressSelected}
                  setAddressSelected={setAddressSelected}
                  setLeaveAtDoor={setLeaveAtDoor}
                  leaveAtDoor={leaveAtDoor}
                />
              )} */}

              {brandContextData._id === "64b80d8a5bdf376def855b1e" ? (
                <Row justify="center" style={{ marginTop: 10 }}>
                  <Col xl={12} lg={18} md={22} xs={24}>
                    <Text style={{ fontWeight: "bold", fontSize: 18 }}>
                      Instagram
                    </Text>
                    <Input
                      placeholder="(optional)"
                      maxLength={140}
                      value={instagramHandle}
                      onChange={(e) => setInstagramHandle(e.target.value)}
                    />
                  </Col>
                </Row>
              ) : null}

              {brandContextData._id === "64b80d8a5bdf376def855b1e" ? (
                <Row justify="center" style={{ marginTop: 10 }}>
                  <Col xl={12} lg={18} md={22} xs={24}>
                    <Text style={{ fontWeight: "bold", fontSize: 18 }}>
                      LinkedIn
                    </Text>
                    <Input
                      placeholder="(optional)"
                      maxLength={140}
                      value={linkedInHandle}
                      onChange={(e) => setLinkedInHandle(e.target.value)}
                    />
                  </Col>
                </Row>
              ) : null}

              {/* {takeoutMethod !== "admission" &&
              stadiumData.stadTypes[0] !== "STT_RETAIL" &&
              stadiumData?._id !== "f6fquSWYZXQqtmS2t" ? (
                <AllergySection
                  hasAllergies={hasAllergies}
                  setHasAllergies={setHasAllergies}
                  allergiesNote={allergiesNote}
                  setAllergiesNote={setAllergiesNote}
                />
              ) : null} */}
              {/* {takeoutMethod !== "admission" &&
              parseInt(orderTotalWithEverything) !== 0 &&
              brandContextData._id !== "64ee4ca45e7521f5e54045fc" &&
              brandContextData._id !== "64ad6cccf9ce9655bf2f36f9" &&
              brandContextData._id !== "655d24864a91a07f4e8fb68b" &&
              stadiumData?._id !== "f6fquSWYZXQqtmS2t" ? (
                <TippingSelection
                  setTipPercentage={setTipPercentage}
                  tipPercentage={tipPercentage}
                  tipDollarAmount={tipDollarAmount}
                  setTipDollarAmount={setTipDollarAmount}
                  takeoutMethod={takeoutMethod}
                />
              ) : null} */}
              <OrderBreakdown
                cartPointsObj={cartPointsObj}
                userPoints={userPoints}
                pointsRedemptionAmount={pointsRedemptionAmount}
                stadiumData={stadiumData}
                stadiumChosenToCheckoutID={location.state.cart.stadiumID}
                takeoutMethod={takeoutMethod}
                tipPercentage={tipPercentage}
                tipDollarAmount={tipDollarAmount}
                // TODO Delivery addition
                // addressSelected={addressSelected}
                orderTotalWithEverything={orderTotalWithEverything}
                setOrderTotalWithEverything={setOrderTotalWithEverything}
                hstToShow={hstToShow}
                setHstToShow={setHstToShow}
                deliveryPrice={deliveryPrice}
                setDeliveryPrice={setDeliveryPrice}
                orderSubtotal={orderSubtotal}
                setOrderSubtotal={setOrderSubtotal}
                tipAmount={tipAmount}
                setTipAmount={setTipAmount}
                orderTotalWithTax={orderTotalWithTax}
                setOrderTotalWithTax={setOrderTotalWithTax}
                showAddressTooFar={showAddressTooFar}
                setShowAddressTooFar={setShowAddressTooFar}
                digitalFeeObj={digitalFeeObj}
                setDigitalFeeObj={setDigitalFeeObj}
                serviceFeeToShow={serviceFeeToShow}
                setServiceFeeToShow={setServiceFeeToShow}
                setComputingBreakdown={setComputingBreakdown}
                computingBreakdown={computingBreakdown}
              />

              {isSafari && <div style={{ marginBottom: 40 }}></div>}
              <Row justify="center" style={{ paddingTop: 20 }}>
                <Col xl={12} lg={18} md={22} xs={24}>
                  {orderTotalWithEverything === "0.00" ? (
                    <Button
                      size="large"
                      style={{
                        width: "100%",
                        backgroundColor: "white",
                        color: token.colorSecondary,
                        fontWeight: 600,
                      }}
                      disabled={computingBreakdown || placingOrder}
                      onClick={() => createTheOrder({})}
                    >
                      {placingOrder ? "Placing Order.." : "Place Order"}
                    </Button>
                  ) : (
                    <Button
                      style={{
                        width: "100%",
                        backgroundColor: "white",
                        color: token.colorSecondary,
                        fontWeight: 600,
                      }}
                      size="large"
                      disabled={computingBreakdown}
                      onClick={() => createTheOrder({})}
                    >
                      {computingBreakdown
                        ? "Calculating payment..."
                        : `Continue to Payment - ${formatPrice(
                            orderTotalWithEverything
                          )}`}
                    </Button>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </Content>
      </Spin>
    </Layout>
  );
};

export default Checkout;
